import React from "react";
import ServiceCard from "./ServiceCard";
import { Link } from "react-router-dom";
import image1 from '../../assets/images/brand/contract.jpeg'
import image2 from '../../assets/images/brand/josh.jpeg'
import image7 from '../../assets/images/brand/key.jpeg'
import image3 from '../../assets/images/brand/mediatest.jpeg'
import image4 from '../../assets/images/brand/payroll.jpeg'
import image5 from '../../assets/images/brand/talent.jpeg'
import image8 from '../../assets/images/brand/campus.png'
import image6 from '../../assets/images/brand/pulse-survey.png'


function HomeService() {
  const serviceData = [
    {
      title: "Recruitment and Talent Acquisition",
      image: image5, // Replace with actual image URL
      description:
        "Comprehensive recruitment solutions, from job postings to conducting interviews, ensuring you hire top talent that fits your team.",
    },
    {
      title: "Campus Hiring",
      image: image8, // Replace with actual image URL
      description:
        "Connecting with universities and colleges to identify and recruit promising young professionals for your organization.",
    },
    {
      title: "PulseCheck Surveys",
      image: image6, // Replace with actual image URL
      description:
        "Customized surveys to gather employee feedback, empowering you to make informed decisions for a positive workplace culture.",
    },
    {
      title: "Employee Training",
      image: image3, // Replace with actual image URL
      description:
        "Tailored training programs to empower your employees with the skills they need to excel and grow within your organization.",
    },
    {
      title: "Payroll and Benefits Administration",
      image: image4, // Replace with actual image URL
      description:
        "Handling payroll processing, benefits management, and compliance, ensuring accuracy and timely delivery of compensation.",
    },
    {
      title: "Employee Engagement Programs",
      image: image2, // Replace with actual image URL
      description:
        "Create meaningful engagement initiatives such as employee outings and corporate events to foster team spirit and build morale.",
    },
  ];

  return (
    <>
      <div class="container-xxl">
        <div class="container">
          <h1 class="text-center " data-wow-delay="0.1s">
           Our Services
          </h1>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          flexWrap: "wrap",
        }}
      >
        {serviceData.map((service, index) => (
          <ServiceCard
            title={service.title}
            image={service.image}
            description={service.description}
            key={index}
          />
        ))}
         <div className='d-flex justify-content-center align-items-center mt-4'>
            <Link class="btn btn-primary py-3 px-5 text-center fadeInUp" to={"/our-service"}>View All Service</Link>
            </div>
      </div>
    </>
  );
}

export default HomeService;
