import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchJobById } from "../../redux/slices/job/jobSlice";
import MainLoader from "../../shared-components/loader/MainLoader";
import CreateJob from "./CreateJob";
import JobCreationForm from "./component/JobForm";

function JobUpdate() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { jobDetails, isLoading, error } = useSelector((state) => state.job);

  useEffect(() => {
    dispatch(fetchJobById(id));
  }, [id]);
  return <>
    { isLoading &&(<MainLoader/>)}
    <JobCreationForm data={jobDetails} btnLabel='Update'/>
  </>;
}

export default JobUpdate;
