import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { POST, GET } from "../../../services/axiosRequestHandler"; // Adjust this according to your GET method setup
import { ERROR_MESSAGE } from "../../../utils/PropertyResolver";
import { API_END_POINT } from "../../../utils/ApiEndPoints";
import toast from "react-hot-toast";

// Define the initial state
const userState = {
  isLoading: false,
  error: null,
  userProfile: {}, // User profile information
};

// Fetch user profile
export const fetchUserProfile = createAsyncThunk(
  "fetchUserProfile",
  async (_, thunkAPI) => {
    try {
      const response = await GET(API_END_POINT.PROFILE);
      if (response?.status === 200) {
        return response.response?.data?.data;
      } else {
        return thunkAPI.rejectWithValue(ERROR_MESSAGE.FETCH_PROFILE_FAILED);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(ERROR_MESSAGE.FETCH_PROFILE_FAILED);
    }
  }
);

// Update user profile
export const completeProfile = createAsyncThunk(
  "completeProfile",
  async (userData, thunkAPI) => {
    try {
      const headers = {
        "Content-Type": "multipart/form-data",
      };
      const response = await POST(API_END_POINT.COMPLETE_PROFILE, userData, headers);
      if (response?.status === 200) {
        toast.success("Profile updated successfully!");
        return response.response?.data?.data;
      } else {
        return thunkAPI.rejectWithValue(ERROR_MESSAGE.UPDATE_PROFILE_FAILED);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(ERROR_MESSAGE.UPDATE_PROFILE_FAILED);
    }
  }
);

// User slice
const userSlice = createSlice({
  name: "user",
  initialState: userState,
  reducers: {
    resetUserState: (state) => {
      state.isLoading = false;
      state.error = null;
      state.userProfile = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch user profile
      .addCase(fetchUserProfile.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchUserProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userProfile = action.payload;
      })
      .addCase(fetchUserProfile.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || ERROR_MESSAGE.FETCH_PROFILE_FAILED;
      });
  },
});

export const { resetUserState } = userSlice.actions;

export default userSlice.reducer;
