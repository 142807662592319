import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GET, POST } from "../../../services/axiosRequestHandler";
import { ERROR_MESSAGE } from "../../../utils/PropertyResolver";
import { API_END_POINT } from "../../../utils/ApiEndPoints";
import toast from "react-hot-toast";

// Define the initial state
const contactState = {
  isLoading: false,
  error: null,
  contactList: [],
  contactDetails: {},
};

// Fetch all contact messages
export const fetchContactList = createAsyncThunk(
  "contact/fetchContactList",
  async (param, thunkAPI) => {
    try {
      const response = await GET(API_END_POINT.GET_ALL_CONTACTS, param);
      if (response?.status === 200) {
        return response.response?.data?.data;
      } else {
        return thunkAPI.rejectWithValue(
          ERROR_MESSAGE.FETCH_CONTACT_LIST_FAILED
        );
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(ERROR_MESSAGE.FETCH_CONTACT_LIST_FAILED);
    }
  }
);

// Fetch contact message by ID
export const fetchContactById = createAsyncThunk(
  "contact/fetchContactById",
  async (contactId, thunkAPI) => {
    try {
      const response = await GET(
        `${API_END_POINT.GET_CONTACT_BY_ID}/${contactId}`
      );
      if (response?.status === 200) {
        return response.response?.data?.data;
      } else {
        return thunkAPI.rejectWithValue(
          ERROR_MESSAGE.FETCH_CONTACT_BY_ID_FAILED
        );
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(ERROR_MESSAGE.FETCH_CONTACT_BY_ID_FAILED);
    }
  }
);

// Create a new contact message
export const createContact = createAsyncThunk(
  "contact/createContact",
  async (contactData, thunkAPI) => {
    try {
      const response = await POST(API_END_POINT.CREATE_CONTACT, contactData);
      if (response?.status === 200) {
        toast.success("Contact message sent successfully!");
        return response.response?.data?.data;
      } else {
        toast.error(response.response?.data?.error);
        return thunkAPI.rejectWithValue(ERROR_MESSAGE.CREATE_CONTACT_FAILED);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(ERROR_MESSAGE.CREATE_CONTACT_FAILED);
    }
  }
);

export const markAsRead = createAsyncThunk(
  "contact/markAsRead",
  async (id, thunkAPI) => {
    try {
      const response = await POST(API_END_POINT.UPDATE_CONTACT_STATUS, id);
      if (response?.status === 200) {
        toast.success("Mark as read successfully!");
        return id;
      } else {
        toast.error(response.response?.data?.error);
        return thunkAPI.rejectWithValue(ERROR_MESSAGE.CREATE_CONTACT_FAILED);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(ERROR_MESSAGE.CREATE_CONTACT_FAILED);
    }
  }
);

// Contact slice
const contactSlice = createSlice({
  name: "contact",
  initialState: contactState,
  reducers: {
    resetContactState: (state) => {
      state.isLoading = false;
      state.error = null;
      state.contactList = [];
      state.contactDetails = {};
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch contact list
      .addCase(fetchContactList.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchContactList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.contactList = action.payload;
      })
      .addCase(fetchContactList.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || ERROR_MESSAGE.FETCH_CONTACT_LIST_FAILED;
      })

      // Fetch contact by ID
      .addCase(fetchContactById.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchContactById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.contactDetails = action.payload;
      })
      .addCase(fetchContactById.rejected, (state, action) => {
        state.isLoading = false;
        state.error =
          action.payload || ERROR_MESSAGE.FETCH_CONTACT_BY_ID_FAILED;
      })

      // Create new contact
      .addCase(createContact.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createContact.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(createContact.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || ERROR_MESSAGE.CREATE_CONTACT_FAILED;
      })
      .addCase(markAsRead.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(markAsRead.fulfilled, (state, action) => {
        state.isLoading = false;
        state.contactList = state.contactList.filter(contact => 
            contact.id !== action.payload.id
        );
    })    
      .addCase(markAsRead.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || ERROR_MESSAGE.SOMETHING_WENT_WRONG;
      });
  },
});

export const { resetContactState } = contactSlice.actions;

export default contactSlice.reducer;
