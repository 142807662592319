// Table.js
import React, { useEffect } from "react";
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
} from "@mui/material";
import { useTable, usePagination } from "react-table";
import ActionButton from "../actionButton/ActionButton";

function Table({ columns, data, fetchData, pageCount, actionButtons }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      manualPagination: true,
      pageCount,
    },
    usePagination
  );

  useEffect(() => {
    fetchData({ pageIndex, pageSize });
  }, [fetchData, pageIndex, pageSize]);

  return (
    <Paper elevation={3}>
      <TableContainer>
        <MuiTable {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </TableCell>
                ))}
                
                <TableCell></TableCell>
                <TableCell></TableCell>

              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <TableCell {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </TableCell>
                  ))}
                 <TableCell>
  {actionButtons?.map((buttonConfig, index) => {
    // Only render the button if `isVisible` is true or undefined (if not provided)
    const isVisible = buttonConfig.isVisible
      ? buttonConfig.isVisible(row.original)
      : true;

    return (
      isVisible && (
        <ActionButton
          key={index}
          label={buttonConfig.label}
          onClick={() => buttonConfig.onClick(row.original)}
          variant={buttonConfig.variant}
          color={buttonConfig.color}
        />
      )
    );
  })}
</TableCell>

                </TableRow>
              );
            })}
          </TableBody>
        </MuiTable>
      </TableContainer>
      <TablePagination
        component="div"
        count={pageCount * pageSize}
        page={pageIndex}
        onPageChange={(_, newPage) => {
          gotoPage(newPage);
        }}
        rowsPerPage={pageSize}
        onRowsPerPageChange={(event) => {
          setPageSize(Number(event.target.value));
        }}
        rowsPerPageOptions={[10, 20, 30, 40, 50]}
        nextIconButtonProps={{ disabled: !canNextPage }}
        backIconButtonProps={{ disabled: !canPreviousPage }}
      />
    </Paper>
  );
}

export default Table;
