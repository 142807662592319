import React, { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../shared-components/table/Table";
import {
  fetchAppliedJobList,
  updateJobStatus,
} from "../../redux/slices/jobApplication/jobApplicationSlice";
import MainLoader from "../../shared-components/loader/MainLoader";
import {
  Modal,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
} from "@mui/material";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
function ApplicationList() {
  const dispatch = useDispatch();
  const { appliedJobList, isLoading, error } = useSelector(
    (state) => state.jobApplication
  );
  const [open, setOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [currentJobId, setCurrentJobId] = useState(null);

  const handleOpen = (id) => {
    setCurrentJobId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedStatus("");
    setCurrentJobId(null);
  };

  // Memoize the fetchData function to prevent infinite API calls
  const fetchData = useCallback(
    ({ pageIndex, pageSize }) => {
      dispatch(fetchAppliedJobList({ page: pageIndex + 1, limit: pageSize }));
    },
    [dispatch]
  );

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const handleSubmit = () => {
    if (currentJobId && selectedStatus) {
      dispatch(
        updateJobStatus({ jobId: currentJobId, status: selectedStatus })
      ).then(() => {
        dispatch(fetchAppliedJobList({ page: 1, limit: 10 }));
        handleClose(); // Close modal after successful submission
      });
    }
  };

  const handleViewApplication = (id) => {
    handleOpen(id);
  };

  const handleDownload = (url) => {
    // Create an anchor element dynamically
    const link = document.createElement('a');
    
    // Set the href to the file URL
    link.href = url;
    
    
    
    // Append the anchor to the body
    document.body.appendChild(link);
    
    // Programmatically trigger a click event on the anchor
    link.click();
    
    // Remove the anchor from the DOM after the click
    document.body.removeChild(link);
  };
  

  const columns = [
    {
      Header: "Name",
      accessor: "user.firstName",
    },
    {
      Header: "Email",
      accessor: "user.email",
    },
    {
      Header: "Job Title",
      accessor: "job.jobTitle",
    },
    {
      Header: "Company",
      accessor: "job.client.companyName",
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Applied At",
      accessor: "createdAt",
      Cell: ({ value }) => new Date(value).toLocaleDateString(),
    },
   
  ];

  const actionButtons = [
    {
      label: "Download Resume",
      onClick: (row) => handleDownload(row?.user?.candidate?.resume),
      variant: "contained",
      color: "primary",
    },
    {
      label: "Edit status",
      onClick: (row) => handleViewApplication(row?.jobId),
      variant: "contained",
      color: "primary",
    },
   
  ];

  return (
    <>
      <div className="container mx-auto p-4">
        <h1 className="text-2xl font-bold mb-4">Applied Jobs</h1>
        {isLoading && <MainLoader />}
        {error && <p>Error: {error}</p>}
        <Table
          columns={columns}
          data={appliedJobList.data || []} // Data from Redux
          fetchData={fetchData}
          pageCount={Math.ceil(appliedJobList.totalCount / 10)}
          actionButtons={actionButtons}
        />
      </div>

      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <h2 className="text-xl font-bold mb-4">Edit Job Status</h2>
          <FormControl fullWidth>
            <InputLabel>Status</InputLabel>
            <Select
              value={selectedStatus}
              onChange={handleStatusChange}
              label="Status"
            >
              <MenuItem value="Pending">Pending</MenuItem>
              <MenuItem value="In Progress">In Progress</MenuItem>
              <MenuItem value="Completed">Completed</MenuItem>
              <MenuItem value="Rejected">Rejected</MenuItem>
            </Select>
          </FormControl>
          <div className="flex justify-end mt-4">
            <Button variant="outlined" color="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              style={{ marginLeft: "10px" }}
            >
              Submit
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default ApplicationList;
