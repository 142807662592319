import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GET, POST } from "../../../services/axiosRequestHandler";
import { ERROR_MESSAGE } from "../../../utils/PropertyResolver";
import { API_END_POINT } from "../../../utils/ApiEndPoints";
import toast from "react-hot-toast";

// Define the initial state
const jobState = {
  isLoading: false,
  error: null,
  jobCategories: [],
  jobList: {
    data: [],
    count: 0,
  },
  jobDetails: {},
  appliedJobs: [],
};

// Fetch all job categories
export const fetchJobCategories = createAsyncThunk(
  "job/fetchJobCategories",
  async (_, thunkAPI) => {
    try {
      const response = await GET(API_END_POINT.GET_ALL_JOB_CATEGORIES);
      if (response?.status === 200) {
        return response.response?.data?.data;
      } else {
        return thunkAPI.rejectWithValue(
          ERROR_MESSAGE.FETCH_JOB_CATEGORIES_FAILED
        );
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(
        ERROR_MESSAGE.FETCH_JOB_CATEGORIES_FAILED
      );
    }
  }
);

export const createJobCategories = createAsyncThunk(
  "job/createJobCategories",
  async (payload, thunkAPI) => {
    try {
      const response = await POST(API_END_POINT.CREATE_JOB_CATEGORY, payload);
      if (response?.status === 200 || 201) {
        toast.success("Category created successfully");
        return response.response?.data?.data;
      } else {
        toast.error(response.response?.data?.message);
        return thunkAPI.rejectWithValue(
          ERROR_MESSAGE.CREATE_JOB_CATEGORIES_FAILED
        );
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(
        ERROR_MESSAGE.CREATE_JOB_CATEGORIES_FAILED
      );
    }
  }
);

// Fetch all jobs
export const fetchJobList = createAsyncThunk(
  "job/fetchJobList",
  async (payload, thunkAPI) => {
    try {
      const response = await GET(API_END_POINT.GET_ALL_JOBS, payload);
      if (response?.status === 200) {
        const data = {
          data: response.response?.data?.data?.jobs,
          count: response.response?.data?.data?.totalJobs,
        };

        return data;
      } else {
        return thunkAPI.rejectWithValue(ERROR_MESSAGE.FETCH_JOB_LIST_FAILED);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(ERROR_MESSAGE.FETCH_JOB_LIST_FAILED);
    }
  }
);

// Fetch job by ID
export const fetchJobById = createAsyncThunk(
  "job/fetchJobById",
  async (jobId, thunkAPI) => {
    try {
      const response = await GET(`${API_END_POINT.GET_JOB_BY_ID}/${jobId}`);
      if (response?.status === 200) {
        return response.response?.data?.data;
      } else {
        return thunkAPI.rejectWithValue(ERROR_MESSAGE.FETCH_JOB_BY_ID_FAILED);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(ERROR_MESSAGE.FETCH_JOB_BY_ID_FAILED);
    }
  }
);

// Create a new job
export const createJob = createAsyncThunk(
  "job/createJob",
  async (jobData, thunkAPI) => {
    try {
      const response = await POST(API_END_POINT.CREATE_JOB, jobData);
      if (response?.status === 200) {
        toast.success("Job created successfully!");
        return response.response?.data?.data;
      } else {
        return thunkAPI.rejectWithValue(ERROR_MESSAGE.CREATE_JOB_FAILED);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(ERROR_MESSAGE.CREATE_JOB_FAILED);
    }
  }
);

// Create a new job
export const applyJob = createAsyncThunk(
  "job/applyJob",
  async (payload, thunkAPI) => {
    try {
      const response = await POST(API_END_POINT.JOB_APPLY, payload);
      if (response?.status === 200) {
        toast.success("Job apply successfully!");
        return response.response?.data?.data;
      } else {
        toast.error(response.response?.data?.error);
        return thunkAPI.rejectWithValue(ERROR_MESSAGE.JOB_APPLY_FAILED);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(ERROR_MESSAGE.JOB_APPLY_FAILED);
    }
  }
);

// Fetch all jobs
export const fetchAppliedJobs = createAsyncThunk(
  "job/fetchAppliedJobs",
  async (payload, thunkAPI) => {
    try {
      const response = await GET(API_END_POINT.GET_APPLIED_JOBS, payload);
      if (response?.status === 200) {
        return response.response?.data?.data;
      } else {
        return thunkAPI.rejectWithValue(ERROR_MESSAGE.FETCH_JOB_LIST_FAILED);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(ERROR_MESSAGE.FETCH_JOB_LIST_FAILED);
    }
  }
);

// Fetch all jobs
export const deleteJob = createAsyncThunk(
  "job/deleteJob",
  async (id, thunkAPI) => {
    try {
      const response = await POST(API_END_POINT.DELETE_JOB, {id});
      if (response?.status === 200) {
        toast.success(response.response?.data?.message);
        console.log('@@@@@@@@@@@@@', id)
        return id;
      } else {
        toast.error(response.response?.data?.message);
        return thunkAPI.rejectWithValue(ERROR_MESSAGE.FETCH_JOB_LIST_FAILED);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(ERROR_MESSAGE.FETCH_JOB_LIST_FAILED);
    }
  }
);

export const updateJob = createAsyncThunk(
  "job/updateJob",
  async (payload, thunkAPI) => {
    try {
      const response = await POST(API_END_POINT.UPDATE_JOB, payload);
      if (response?.status === 200) {
        toast.success(response.response?.data?.message);
        return response.response?.data?.data;
      } else {
        toast.error(response.response?.data?.message);
        return thunkAPI.rejectWithValue(ERROR_MESSAGE.FETCH_JOB_LIST_FAILED);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(ERROR_MESSAGE.FETCH_JOB_LIST_FAILED);
    }
  }
);

// Job slice
const jobSlice = createSlice({
  name: "job",
  initialState: jobState,
  reducers: {
    resetJobState: (state) => {
      state.isLoading = false;
      state.error = null;
      state.jobCategories = [];
      state.jobList = {
        data: [],
        count: 0,
      };
      state.jobDetails = {};
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch job categories
      .addCase(fetchJobCategories.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchJobCategories.fulfilled, (state, action) => {
        state.isLoading = false;
        state.jobCategories = action.payload;
      })
      .addCase(fetchJobCategories.rejected, (state, action) => {
        state.isLoading = false;
        state.error =
          action.payload || ERROR_MESSAGE.FETCH_JOB_CATEGORIES_FAILED;
      })

      // Fetch job list
      .addCase(fetchJobList.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchJobList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.jobList = action.payload;
      })
      .addCase(fetchJobList.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || ERROR_MESSAGE.FETCH_JOB_LIST_FAILED;
      })

      // Fetch job by ID
      .addCase(fetchJobById.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchJobById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.jobDetails = action.payload;
      })
      .addCase(fetchJobById.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || ERROR_MESSAGE.FETCH_JOB_BY_ID_FAILED;
      })
      .addCase(fetchAppliedJobs.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchAppliedJobs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.appliedJobs = action.payload;
      })
      .addCase(fetchAppliedJobs.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || ERROR_MESSAGE.FETCH_JOB_BY_ID_FAILED;
      }).addCase(deleteJob.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteJob.fulfilled, (state, action) => {
        state.isLoading = false;
        const data = state.jobList.data.filter(job => 
            job.id !== action.payload
        );
        const newCount = state.jobList.count -1;
        state.jobList = {
          data,
          count: newCount
        }
    })    
      .addCase(deleteJob.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || ERROR_MESSAGE.SOMETHING_WENT_WRONG;
      });
  },
});

export const { resetJobState } = jobSlice.actions;

export default jobSlice.reducer;
