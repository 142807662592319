import React, { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../shared-components/table/Table";

import {
  fetchJobList, // API call to fetch jobs
  deleteJob,
  fetchJobById, // API call to delete a job
} from "../../../redux/slices/job/jobSlice"; // Replace with your job slice

import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import MainLoader from "../../../shared-components/loader/MainLoader";
import { useNavigate } from "react-router-dom";

function JobList() {
  const dispatch = useDispatch();
  const { jobList, isLoading, error } = useSelector((state) => state.job);
  const [pageCount, setPageCount] = useState(0);
  const [filter, setFilter] = useState("active"); // To track the selected filter
  const navigate = useNavigate();
  // Fetch data and handle pagination
  const fetchData = useCallback(
    ({ pageIndex, pageSize }) => {
      const param = `?page=${pageIndex + 1}&limit=${pageSize}&filter=${
        filter === "all" ? "" : filter
      }`;
      dispatch(fetchJobList(param))
        .then((response) => {
          if (response.payload?.data?.total) {
            setPageCount(Math.ceil(response.payload.data.total / pageSize));
          }
        })
        .catch((err) => console.error("Error fetching job list:", err));
    },
    [dispatch, filter]
  );

  const handleView = (id) => {
    navigate(`/admin/job-view/${id}`)
  };

  const handleEdit = (id) => {
    navigate(`/admin/job-update/${id}`)

  };

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this job?")) {
      dispatch(deleteJob(id))
        .then(() => {
         
        })
        .catch((err) => console.error("Error deleting job:", err));
    }
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  // Columns definition
  const columns = [
    {
      Header: "Job Title",
      accessor: "jobTitle", // Adjust to match your data
    },
    {
      Header: "Job Type",
      accessor: "jobType", // Adjust to match your data
    },
    {
      Header: "Company Name",
      accessor: "client.companyName", // Adjust to match your data
    },
    
    {
      Header: "End Date",
      accessor: "lastDate",
      Cell: ({ value }) => new Date(value).toLocaleDateString(),
    },
  ];

  // Define the action buttons
  const actionButtons = [
    {
      label: "View",
      onClick: (row) => handleView(row.id),
      variant: "contained",
      color: "primary",
    },
    {
      label: "Edit",
      onClick: (row) => handleEdit(row.id),
      variant: "contained",
      color: "secondary",
    },
    {
      label: "Delete",
      onClick: (row) => handleDelete(row.id),
      variant: "contained",
      color: "error",
      isVisible: (row) => row.deletedAt === null,
    },
  ];

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Job List</h1>

      {/* Filter dropdown */}
      <FormControl style={{ width: "200px" }}>
        <InputLabel id="filter-label">Filter</InputLabel>
        <Select
          labelId="filter-label"
          id="filter-select"
          value={filter}
          label="Filter"
          onChange={handleFilterChange}
        >
          <MenuItem value="all">All Jobs</MenuItem>
          <MenuItem value="active">Active Jobs</MenuItem>
          <MenuItem value="expired">Expired Jobs</MenuItem>
        </Select>
      </FormControl>
      <hr />

      {isLoading && <MainLoader />}
      {error && <p>Error: {error}</p>}
      <Table
        columns={columns}
        data={jobList?.data || []}
        fetchData={fetchData}
        pageCount={pageCount}
        actionButtons={actionButtons}
      />
    </div>
  );
}

export default JobList;
