import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import carouselone from '../../assets/images/carousal/carousel-1.jpeg';
import carouseltwo from '../../assets/images/carousal/carousel-2.jpeg';
import carouselthre from '../../assets/images/carousal/carousel-3.jpeg';
import './Hero.css'

import { Link } from 'react-router-dom';

function HeaderCarousel() {
  return (
    <div className="container-fluid p-0">
      <Carousel
        showThumbs={false}
        showStatus={false}
        infiniteLoop
        useKeyboardArrows
        autoPlay
        interval={5000}
        transitionTime={1000}
      >
        <div className="position-relative">
          <img className="img-fluid" src={carouseltwo} alt="Carousel 1" />
          <div
            className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center"
            style={{ background: 'rgba(43, 57, 64, .5)' }}
          >
            <div className="container">
              <div className="row justify-content-start">
                <div className="col-10 col-lg-8 carousel-caption-test">
                  <h1 className="display-3 text-white animated slideInDown mb-4">
                    Find Top Talent, Achieve Greater Success
                  </h1>
                  <p className="fs-5 fw-medium text-white mb-4 pb-2">
                   Connect with exceptional candidates and build you winning team with MV Hiring. Start your recruitment journey today!
                  </p>
                  <Link to="/our-service" className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft carousel-button">
                   Our Service
                  </Link>
                  <Link to="/contact" className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft carousel-button">
                  Contact Us
                  </Link>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="position-relative">
          <img className="img-fluid" src={carouselthre} alt="Carousel 2" />
          <div
            className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center"
            style={{ background: 'rgba(43, 57, 64, .5)' }}
          >
            <div className="container">
              <div className="row justify-content-start">
                <div className="col-10 col-lg-8 carousel-caption-test">
                  <h1 className="display-3 text-white animated slideInDown mb-4">
                    Elevate Your Career
                  </h1>
                  <p className="fs-5 fw-medium text-white mb-4 pb-2">
                    Discover exciting opportunities and connect with top employers. Find your next great job with MV Hiring today!
                  </p>
                  <Link to="/job-list" className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft carousel-button">
                    Search A Job
                  </Link>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
       
      
      </Carousel>
    </div>
  );
}

export default HeaderCarousel;
